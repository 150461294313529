import React from "react";
import aboutThumb from "../../../assets/images/about/about-us.png";

export default function MainSection() {
  return (
    <section className="about rel z-1 pt-150 rpt-30 pb-120">
      <div className="container">
        <div className="row align-items-center">
          {/* <div className="col-lg-7">
            <div className="about-image rmb-60">
              <img src={aboutThumb} alt="About" />
            </div>
          </div> */}
          <div className="col-lg-12">
            <div className="about-content">
              <div className="section-title mb-30">
                <span className="sub-title">
                  At Next Cosmos Technology, we are not just a technology
                  company – we are the architects of the digital future. As a
                  global leader in IT solutions, we redefine the boundaries of
                  what's possible and empower businesses to thrive in the
                  digital era{" "}
                </span>
                <h2>Welcome to Next Cosmos Technology</h2>
              </div>
              <p>
                Established in 2022, Next Cosmos Technology has consistently set
                industry standards through a commitment to excellence. We bring
                together a diverse team of brilliant minds, passionate about
                pushing the limits of technology to deliver unparalleled results
                for our clients.
              </p>

              <h3 className="mt-35">Our Vision</h3>
              <p>
                Enabling Transformation, Inspiring Innovation. Our vision is to
                be the catalyst for positive change, providing cutting-edge IT
                solutions that drive growth, foster innovation, and transform
                the way businesses operate.
              </p>
              <h3 className="mt-35">Our Core Values</h3>
              <p>
                <strong>Innovation: </strong>
                We thrive on innovation. Our culture encourages curiosity,
                creativity, and a relentless pursuit of groundbreaking
                solutions. We believe in staying ahead of the curve and driving
                the industry forward through continuous technological
                advancement.
              </p>
              <p>
                <strong>Integrity: </strong>
                Integrity is the foundation of everything we do. We operate with
                honesty, transparency, and a commitment to ethical business
                practices. Our clients trust us to deliver not just solutions
                but lasting partnerships built on integrity.
              </p>
              <p>
                <strong>Excellence: </strong>
                Excellence is non-negotiable. From the quality of our services
                to the expertise of our team, we set high standards and
                consistently exceed them. Our pursuit of excellence ensures that
                every client receives best-in-class solutions.
              </p>

              <div className="row mt-35">
                <div className="col-lg-6">
                  <h3>Our Services</h3>
                  <ul className="list-style-three mt-35 mb-50">
                    <li>IT Consulting</li>
                    <p>
                      <strong>Strategic Planning: </strong>Crafting roadmaps for
                      digital transformation.
                    </p>
                    <p>
                      <strong>Advisory Services: </strong> Expert guidance to
                      optimize IT infrastructure.
                    </p>
                    <p>
                      <strong>Emerging Technologies: </strong> Harnessing the
                      power of AI, blockchain, and more.
                    </p>
                    <li className="mt-35">Software Development</li>
                    <p>
                      <strong>Custom Solutions: </strong> Tailored software to
                      meet unique business needs.
                    </p>
                    <p>
                      <strong>Enterprise Applications: </strong> Streamlining
                      operations with robust solutions.
                    </p>
                    <li className="mt-35">Managed Services</li>
                    <p>
                      <strong>IT Infrastructure Management: </strong> Ensuring
                      the reliability and security of your systems.
                    </p>
                    <p>
                      <strong>Cloud Solutions: </strong> Leveraging the
                      scalability and flexibility of cloud technology.
                    </p>
                    <p>
                      <strong>24/7 Support: </strong> Round-the-clock assistance
                      for uninterrupted operations.
                    </p>
                  </ul>
                </div>
                <div className="col-lg-6">
                  <div className="about-image rmb-60">
                    <img src={aboutThumb} alt="About" />
                  </div>
                </div>
              </div>
              {/* <ul className="list-style-three mt-35 mb-50">
                <li>Collaborative Approach</li>
                <li>Innovation at Heart</li>
                <li>Quality and Excellence</li>
                <li>User-Centric Design</li>
                <li>Timely Delivery</li>
                <li>Innovation at Heart</li>
              </ul> */}
              <a href="/contact" className="theme-btn style-eight">
                Get in Touch <i className="fas fa-long-arrow-alt-right"></i>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="circle-drop"></div>
    </section>
  );
}
