import React, { useState } from "react";
import DataIteration from "../helpers/DataIteration";
import ServicesTab from "./ServicesTab";
import CoursesTab from "./CoursesTab";

function AboutSection() {
  const tabContent = [
    {
      id: 1,
      uniqKey: "_services",
      content: "Our Services",
      companent: <ServicesTab />,
    },
    {
      id: 2,
      uniqKey: "_courses",
      content: "Online Courses",
      companent: <CoursesTab />,
    },
  ];

  const [tab, setTab] = useState(tabContent[0].id);
  const tabHandler = (e, value) => {
    e.preventDefault();
    setTab(value);
  };

  return (
    <section className="about-two pt-100 rpt-80 pb-195 rpb-100">
      <div className="container-fluid clearfix pl-70 pr-70">
        <div className="col-sm-9 offset-sm-3">
          <ul className="nav about-tab justify-content-start">
            <DataIteration datas={tabContent} startLength={0} endLength={2}>
              {({ datas }) => (
                <li
                  key={datas.id + datas.uniqKey}
                  className={
                    tab === datas.id ? "active  rounded-top" : "rounded-top"
                  }
                >
                  <a
                    onClick={(e) => tabHandler(e, datas.id)}
                    data-toggle="tab"
                    href="#"
                  >
                    <h3>{datas.content}</h3>
                  </a>
                </li>
              )}
            </DataIteration>
          </ul>
        </div>

        <div className="tab-content about-tab-content">
          <DataIteration datas={tabContent} startLength={0} endLength={2}>
            {({ datas }) => (
              <div
                key={datas.id + datas.uniqKey}
                className={`tab-pane fade ${
                  tab === datas.id ? "show active" : ""
                }`}
                id="tabContent1"
              >
                {datas.companent}
              </div>
            )}
          </DataIteration>
        </div>
      </div>
    </section>
  );
}

export default AboutSection;
