import React from "react";

export default function WorkprocessSection() {
  return (
    <section className="work-progress-two">
      <div className="container">
        <div className="work-progress-inner-two">
          <div className="row justify-content-center">
            <div className="col-lg-4 col-sm-6">
              <div className="progress-item-two wow fadeInUp delay-0-2s">
                <div className="icon">
                  <i className="flaticon flaticon-seo"></i>
                </div>
                <h3>
                  Advertising and <br />
                  Marketing
                </h3>
                <p>
                  We provide services for brand marketing, advertising, and
                  product promotion in addition to helping to establish brands
                  through conceptualization.
                </p>
                <a href="#" className="learn-more">
                  Read More <i className="fas fa-arrow-right"></i>
                </a>
                <span className="progress-step">01</span>
              </div>
            </div>
            <div className="col-lg-4 col-sm-6">
              <div className="progress-item-two wow fadeInUp delay-0-4s">
                <div className="icon">
                  <i className="flaticon flaticon-web-development"></i>
                </div>
                <h3>
                  Web <br />
                  Development
                </h3>
                <p>
                  Our website developers offer customers professional web
                  application development and web design services.
                </p>
                <a href="#" className="learn-more">
                  Read More <i className="fas fa-arrow-right"></i>
                </a>
                <span className="progress-step">02</span>
              </div>
            </div>
            <div className="col-lg-4 col-sm-6">
              <div className="progress-item-two wow fadeInUp delay-0-6s no-border mb-0">
                <div className="icon">
                  <i className="flaticon flaticon-code"></i>
                </div>
                <h3>Industrial Training</h3>
                <p>
                  In an industrial training course , you will learn about
                  various types of technologies, including those used in
                  networking, design, and telecommunication.
                </p>
                <a href="#" className="learn-more">
                  Read More <i className="fas fa-arrow-right"></i>
                </a>
                <span className="progress-step">03</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
