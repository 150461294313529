import React, { useState } from "react";
import DataIteration from "../../helpers/DataIteration";
import EnquireForm from "./EnquireForm";

function Enquire() {
  const fieldDevelop = [
    {
      labelFor: "name",
      labelName: "Full Name",
      type: "text",
      id: "name",
      name: "name",
      placeholder: "Full Name",
      required: true,
    },
    {
      labelFor: "email",
      labelName: "Email address",
      type: "email",
      id: "email",
      name: "email",
      placeholder: "E-mail id",
      required: true,
    },
    {
      labelFor: "mobile",
      labelName: "Mobile Number",
      type: "number",
      id: "mobile",
      name: "mobile",
      placeholder: "Mobile No",
      required: true,
    },
    {
      labelFor: "purpose",
      labelName: "Purpose",
      type: "text",
      id: "purpose",
      name: "purpose",
      placeholder: "Your Business",
      required: true,
    },
    {
      labelFor: "address",
      labelName: "Address",
      type: "text",
      id: "address",
      name: "address",
      placeholder: "Your Address",
      required: true,
    },
    {
      labelFor: "city",
      labelName: "City",
      type: "text",
      id: "city",
      name: "city",
      placeholder: "City",
      required: true,
    },
    {
      labelFor: "country",
      labelName: "Country",
      type: "text",
      id: "country",
      name: "country",
      placeholder: "Country",
      required: true,
    },
    {
      labelFor: "pincode",
      labelName: "Pin Code",
      type: "number",
      id: "pincode",
      name: "pincode",
      placeholder: "Pincode",
      required: true,
    },
    {
      labelFor: "description",
      labelName: "Bussness Description",
      type: "textarea",
      id: "description",
      name: "description",
      placeholder: "Enter Description",
      rows: 2,
      required: true,
    },
  ];

  const fieldCourses = [
    {
      labelFor: "name",
      labelName: "Full Name",
      type: "text",
      id: "name",
      name: "name",
      placeholder: "Full Name",
      required: true,
    },
    {
      labelFor: "email",
      labelName: "Email address",
      type: "email",
      id: "email",
      name: "email",
      placeholder: "E-mail id",
      required: true,
    },
    {
      labelFor: "mobile",
      labelName: "Mobile No",
      type: "number",
      id: "mobile",
      name: "mobile",
      placeholder: "Mobile No",
      required: true,
    },
    {
      labelFor: "higherEducation",
      labelName: "Higher Education",
      type: "text",
      id: "higherEducation",
      name: "higherEducation",
      placeholder: "Higher Education",
      required: true,
    },
    {
      labelFor: "passoutYear",
      labelName: "Passout Year",
      type: "number",
      id: "passoutYear",
      name: "passoutYear",
      placeholder: "Passout Year",
      required: true,
    },
    {
      labelFor: "age",
      labelName: "Age",
      type: "number",
      id: "age",
      name: "age",
      placeholder: "Your Age",
      required: true,
    },
    {
      labelFor: "profession",
      labelName: "Profession",
      type: "select",
      id: "profession",
      name: "profession",
      placeholder: "Select your Profession",
      option: [
        "Student",
        "Working IT Company",
        "Working Non-IT Company",
        "Recently pass out and Looking for Job",
        "Other",
      ],
      required: true,
    },
    {
      labelFor: "country",
      labelName: "Country",
      type: "text",
      id: "country",
      name: "country",
      placeholder: "Country",
      required: true,
    },
    {
      labelFor: "pincode",
      labelName: "Pin Code",
      type: "number",
      id: "pincode",
      name: "pincode",
      placeholder: "Pincode",
      required: true,
    },
  ];

  const tabContent = [
    {
      id: 1,
      mapID: fieldDevelop,
      uniqKey: "__get_quotation",
      content: "Get Quotation",
    },
    {
      id: 2,
      mapID: fieldCourses,
      uniqKey: "_enroll",
      content: "Enroll for Courses",
    },
  ];

  const [tab, setTab] = useState(tabContent[0].id);
  const tabHandler = (e, value) => {
    e.preventDefault();
    setTab(value);
  };

  return (
    <section className="Enquire-section bg-dark-blue pt-60">
      <div className="container">
        <div className="row">
          <div className="col-lg-7">
            <div className="Enquire-content text-white pt-100 pb-200 rpt-40 rpb-100">
              <span className="sub-title d-block wow fadeInUp delay-0-2s">
                EMPOWER YOUR BUSINESS
              </span>
              <h1 className="wow fadeInUp delay-0-4s mt-20">
                Building Cutting-Edge Software for the Future
              </h1>
              <p>
                We leverage the latest technologies to craft custom software
                solutions that drive success.
              </p>
              <div className="Enquire-btn mt-35 wow fadeInUp delay-0-6s">
                <a href="/contact" className="theme-btn">
                  meet with us
                </a>
              </div>
            </div>
          </div>
          <div className="col-lg-5">
            <div className="Enquire-section-form bg-blue-tran wow fadeInRight delay-0-4s shadow-lg pb-20 rounded">
              <ul className="nav btn-group bg-purpal home-tab py-20 rounded-top">
                <DataIteration datas={tabContent} startLength={0} endLength={2}>
                  {({ datas }) => (
                    <li key={datas.id + datas.uniqKey}>
                      <a
                        onClick={(e) => tabHandler(e, datas.id)}
                        className={tab === datas.id ? "active bg-white" : ""}
                        data-toggle="tab"
                        href="#"
                      >
                        <h3 className="mb-0 px-25 text-white">
                          {datas.content}
                        </h3>
                      </a>
                    </li>
                  )}
                </DataIteration>
              </ul>

              <div className="tab-content px-20 enquiry_mess_align">
                <DataIteration datas={tabContent} startLength={0} endLength={2}>
                  {({ datas }) => (
                    <div
                      key={datas.id + datas.uniqKey}
                      className={`tab-pane fade ${
                        tab === datas.id ? "show active" : ""
                      }`}
                      id="tabContent1"
                    >
                      <EnquireForm formElements={datas.mapID} tab={tab} />
                    </div>
                  )}
                </DataIteration>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="left-circle"></div>
    </section>
  );
}

export default Enquire;
