import React, { useEffect } from "react";
import logoMobile from "../../../../assets/images/logos/logo-mobile.png";
import logo from "../../../../assets/images/logos/logo.png";
import StickyMenu from "../../../../lib/StickyMenu";
import Navigation from "../../../helpers/Navigation";
import MobileHeaderCom from "../../Mobile/MobileHeaderCom";
import { Link } from "react-router-dom";

function HeaderHomeTwo() {
  useEffect(() => {
    StickyMenu(".main-header");
  }, []);

  return (
    <>
      <MobileHeaderCom logo={logoMobile} />
      <header className="main-header header-two text-black bg-white shadow-sm">
        <div className="logo-outer py-0 px-70 d-none d-lg-flex align-items-center">
          <div className="logo">
            <a href="/">
              <img src={logo} alt="Logo" title="Logo" />
            </a>
          </div>
        </div>

        <div className="header-wrap align-self-center">
          <div className="header-top py-10">
            <div className="top-left">
              <ul>
                <li>
                  Call Us: <a href="callto:+91 8851373820">+91 8851373820</a>
                </li>
                <li>
                  Email us:{" "}
                  <a href="mailto:info@nextcosmostechnology.com">
                    contact@nextcosmostechnology.com
                  </a>
                </li>
              </ul>
            </div>
            <div className="top-right">
              <div className="office-time">
                {/* <i className="far fa-clock"></i> */}
                <span>Recognised by central government</span>
              </div>
              <div className="social-style-one">
                <a
                  href="https://www.facebook.com/profile.php?id=100095164452579"
                  target="blank"
                >
                  <i className="fab fa-facebook-f"></i>
                </a>
                <a href="https://twitter.com/NextCosmosTech" target="blank">
                  <i className="fab fa-twitter"></i>
                </a>
                <a
                  href="https://www.linkedin.com/company/103130768/admin/feed/posts/"
                  target="blank"
                >
                  <i className="fab fa-linkedin"></i>
                </a>
                <a
                  href="https://www.instagram.com/next_cosmos_technology"
                  target="blank"
                >
                  <i className="fab fa-instagram"></i>
                </a>
                <a
                  href="https://in.pinterest.com/NextCosmosTechnology"
                  target="blank"
                >
                  <i className="fab fa-pinterest-p"></i>
                </a>
                <a
                  href="https://www.youtube.com/@nextcosmostechnologypvt.ltd."
                  target="blank"
                >
                  <i className="fab fa-youtube"></i>
                </a>
              </div>
            </div>
          </div>
          <div className="header-upper">
            <div className="container-fluid clearfix">
              <div className="header-inner d-flex align-items-center">
                <div className="nav-outer clearfix d-flex align-items-center">
                  <Navigation className="restly-restly-header-two" />
                  <div className="menu-btn">
                    {/* <a href="/contact" className="theme-btn">
                      meet with us
                    </a> */}
                    <Link to="/contact" className="theme-btn">
                      meet with us
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
}

export default HeaderHomeTwo;
