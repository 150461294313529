import React from "react";
import { blogs } from "../../data/blogs.json";
import { teams } from "../../data/Teams.json";
import BacktoTopCom from "../helpers/BackToTopCom";
import FooterHomeTwo from "../partials/Footers";
import HeaderHomeTwo from "../partials/Headers/SubHeader";
import AboutSection from "./AboutSection";
import Enquire from "./enquire/Enquire";
import NewsSection from "./NewsSection";
import Services from "./Services";
import ServiceSection from "./ServiceSection";
import SubscriptionSection from "./SubscriptionSection";
import TeamSection from "./TeamSection";
import WorkprocessSection from "./WorkprocessSection";
import VideoPlayer from "./Video";


function HomeTwo() {
  return (
    <>
      <div className="video-container">
        <VideoPlayer />
        <HeaderHomeTwo />
        <Enquire />
      </div>
      <Services />
      <AboutSection />
      <ServiceSection />
      <TeamSection teams={teams} />
      <WorkprocessSection />
      <SubscriptionSection />
      <NewsSection blogs={blogs} />
      <FooterHomeTwo />
      <BacktoTopCom />
    </>
  );
}

export default HomeTwo;
