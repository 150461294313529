import React from "react";
import background from "../../../assets/images/background/services.png";
import shape2 from "../../../assets/images/shapes/service-line.png";
import shape1 from "../../../assets/images/shapes/service-triangle.png";
import ServiceCard from "./ServiceCard";

export default function MainSection() {
  return (
    <section
      className="services-seven rel text-center py-100 rpy-100"
      style={{ backgroundImage: `url(${background})` }}
    >
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-8">
            <div className="section-title mb-75">
              <span className="sub-title">
                {" "}
                Elevate your online presence with our cutting-edge web
                development and design services. We create visually stunning and
                user-friendly websites that leave a lasting impression.
              </span>
              <h2>
                Transform your ideas into reality with our custom software
                development services.
              </h2>
            </div>
          </div>
        </div>
        <ServiceCard />
        {/* <div className="row justify-content-center">
          <div className="col-lg-4 col-sm-6">
            <div className="service-item-seven">
              <img
                src={
                  require(`../../../assets/images/services/service-seven1.png`)
                    .default
                }
                alt="Service"
              />
              <h3>Web Application Development</h3>
              <a href="/online-courses" className="read-more">
                Click here <i className="fas fa-long-arrow-alt-right"></i>
              </a>
            </div>
          </div>
          <div className="col-lg-4 col-sm-6">
            <div className="service-item-seven">
              <img
                src={
                  require(`../../../assets/images/services/service-seven2.png`)
                    .default
                }
                alt="Service"
              />
              <h3>Enterprise Software Solutions</h3>
              <a href="/online-courses" className="read-more">
                Click here <i className="fas fa-long-arrow-alt-right"></i>
              </a>
            </div>
          </div>
          <div className="col-lg-4 col-sm-6">
            <div className="service-item-seven">
              <img
                src={
                  require(`../../../assets/images/services/service-seven3.png`)
                    .default
                }
                alt="Service"
              />
              <h3>Cloud-based Applications</h3>
              <a href="/online-courses" className="read-more">
                Click here <i className="fas fa-long-arrow-alt-right"></i>
              </a>
            </div>
          </div>
          <div className="col-lg-4 col-sm-6">
            <div className="service-item-seven">
              <img
                src={
                  require(`../../../assets/images/services/service-seven1.png`)
                    .default
                }
                alt="Service"
              />
              <h3>Website Design and Development</h3>
              <a href="/online-courses" className="read-more">
                Click here <i className="fas fa-long-arrow-alt-right"></i>
              </a>
            </div>
          </div>
          <div className="col-lg-4 col-sm-6">
            <div className="service-item-seven">
              <img
                src={
                  require(`../../../assets/images/services/service-seven2.png`)
                    .default
                }
                alt="Service"
              />
              <h3>E-commerce Solutions</h3>
              <a href="/online-courses" className="read-more">
                Click here <i className="fas fa-long-arrow-alt-right"></i>
              </a>
            </div>
          </div>
          <div className="col-lg-4 col-sm-6">
            <div className="service-item-seven">
              <img
                src={
                  require(`../../../assets/images/services/service-seven3.png`)
                    .default
                }
                alt="Service"
              />
              <h3>Content Management Systems (CMS)</h3>
              <a href="/online-courses" className="read-more">
                Click here <i className="fas fa-long-arrow-alt-right"></i>
              </a>
            </div>
          </div>
        </div> */}
      </div>
      <div className="circle-drop-one"></div>
      <div className="circle-drop-two"></div>
      <div className="service-triangle">
        <img src={shape1} alt="Triangle" />
      </div>
      <div className="service-line">
        <img src={shape2} alt="Triangle" />
      </div>
    </section>
  );
}
