import React from "react";

export default function MainSection() {
  return (
    <section className="about rel z-1 pt-150 rpt-30 pb-120">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-12">
            <div className="about-content">
              <div className="section-title mb-30">
                <span className="sub-title">
                  Next Cosmos Technology Pvt Ltd - Terms & Conditions
                </span>
                <h2>Effective Date: 22/05/2024</h2>
              </div>
              <h4>Welcome to Next Cosmos Technology Pvt Ltd</h4>
              <p>
                These Terms & Conditions ("Terms") govern your use of our
                services ("Services"). By using our Services, you agree to be
                bound by these Terms.
              </p>

              <h4>Use of Services</h4>
              <p>
                You may use our Services only for lawful purposes and in
                accordance with these Terms
              </p>

              <h4>Intellectual Property</h4>
              <p>
                The content of our Services is protected by intellectual
                property laws. You may not use any content without our prior
                written permission.
              </p>

              <h4>Disclaimers</h4>
              <p>
                Our Services are provided "as is" and without warranties of any
                kind.
              </p>

              <h4>Limitation of Liability</h4>
              <p>
                We will not be liable for any damages arising out of your use of
                our Services.
              </p>

              <h4>Termination</h4>
              <p>
                We may terminate your access to our Services at any time for any
                reason.
              </p>

              <h4>Governing Law</h4>
              <p>
                These Terms shall be governed by and construed in accordance
                with the laws of India.
              </p>

              <h4>Dispute Resolution</h4>
              <p>
                Any dispute arising out of these Terms shall be resolved by
                [insert dispute resolution mechanism, e.g., arbitration].
              </p>

              <h4>Entire Agreement</h4>
              <p>
                These Terms constitute the entire agreement between you and Next
                Cosmos Technology Pvt Ltd regarding your use of our Services.
              </p>

              <h4>Contact Us</h4>
              <p>
                If you have any questions about these Terms, please contact us
                at info@nextcosmostechnology.com.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="circle-drop"></div>
    </section>
  );
}
