import React, { memo, useState } from "react";
import { QUOTATION_BTN, ENROLL_BTN } from "../../../constant/constant";
import Popup from "./Dialog";

function EnquireForm({ formElements, tab }) {
  const [open, setOpen] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    mobile: "",
    purpose: "",
    address: "",
    city: "",
    country: "",
    pincode: "",
    description: "",
  });

  const handlerSubmitEnquiry = () => {
    // Ensure Content-Type header is set to 'application/json'
    fetch("https://www.nextcosmostechnology.com/api/submit", {
      method: "POST",
      headers: {
        "Content-Type": "application/json", // Indicate that the request body is JSON
      },
      body: JSON.stringify(formData), // Ensure the formData is serialized as JSON
    })
      .then((res) => {
        // Check if the response status is OK (status code 200-299)
        if (!res.ok) {
          // If not, throw an error with the status code and message
          return Promise.reject(`Error: ${res.status} ${res.statusText}`);
        }
        // Parse the JSON response body
        return res;
      })
      .then((data) => {
        // Handle the parsed JSON data (e.g., set the response in state)
        if (data?.status === 200) {
          setOpen(true);
        }
        setOpen(false);
      })
      .catch((error) => {
        // Handle errors (either network or server-related)
        console.error("Error:", error);
      });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handlerSubmit = (event) => {
    event.preventDefault();
    handlerSubmitEnquiry();
  };

  // Function to handle closing the dialog
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <form
      id="EnquireForm"
      className="EnquireForm mt-20 form-row"
      name="EnquireForm"
      action="#"
      method="post"
      onSubmit={handlerSubmit}
    >
      {formElements.map((element) => {
        if (element.type === "textarea") {
          return (
            <div className="form-group col-md-12 mb-20">
              <textarea
                onChange={handleChange}
                type={element.type}
                id={element.id}
                name={element.name}
                className="form-control px-3 py-2 h-auto"
                rows="4"
                placeholder={element.placeholder}
                required
              />
            </div>
          );
        } else {
          return (
            <div className="form-group col-md-6 mb-20">
              <input
                onChange={handleChange}
                type={element.type}
                id={element.id}
                name={element.name}
                className="form-control px-3 py-2"
                placeholder={element.placeholder}
                required
              />
            </div>
          );
        }
      })}
      <div className="col-md-12">
        <button type="submit" className="theme-btn width_per_100">
          {tab === 1 ? QUOTATION_BTN : ENROLL_BTN}
        </button>
      </div>
      <Popup open={open} handleClose={handleClose} />
    </form>
  );
}

export default memo(EnquireForm);
