import React from "react";
import ColumCardStyleTwo from "../helpers/Cards/ColumCardStyleTwo";
import DataIteration from "../helpers/DataIteration";

export default function NewsSection({ blogs, className }) {
  return (
    <section className={`news-section-two py-90 rpb-70 ${className || ""}`}>
      <div className="container">
        {/* <div className="section-title text-center mb-55 mt-120">
          <span className="sub-title">Recorgnized by startup india</span>
          <h2>Center goverment approved</h2>
        </div> */}

        <iframe
          class="shadow-lg p-3 mb-5 bg-white rounded"
          title="map"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3071.178408989668!2d77.31073272939312!3d28.58511991465383!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390ce5fb027329d7%3A0x2428fd0d17538198!2sNext%20Cosmos%20Technology%20Pvt%20Ltd!5e0!3m2!1sen!2sin!4v1688669292849!5m2!1sen!2sin"
          height="400"
          style={{ border: "0", width: "100%" }}
          allowFullScreen=""
          loading="lazy"
        ></iframe>
        {/* <div className="section-title text-center mb-55 mt-120">
          <span className="sub-title">Recent Articles</span>
          <h2>Latest News from Us</h2>
        </div>
        <div className="row justify-content-center">
          <DataIteration datas={blogs} startLength={0} endLength={3}>
            {({ datas }) => (
              <div key={Math.random()} className="col-xl-4 col-md-6">
                <ColumCardStyleTwo datas={datas} />
              </div>
            )}
          </DataIteration>
        </div> */}
      </div>
    </section>
  );
}
