import React from "react";
import TeamCardStyleOne from "../helpers/Cards/TeamCardStyleOne";
import DataIteration from "../helpers/DataIteration";
import video from "../../assets/videos/digital_future.mp4";

function TeamSection({ teams, className }) {
  return (
    <section
      className={`team-section pt-115 rpt-95 pb-60 rpb-40 ${className || ""}`}
    >
      <div className="container">
        {/* <div className="section-title text-center mb-55">
          <span className="sub-title">Team Memabers</span>
          <h2>Our Awesome Team</h2>
        </div>
        <div className="row justify-content-center">
          <DataIteration datas={teams} startLength={0} endLength={3}>
            {({ datas }) => (
              <div key={Math.random()} className="col-lg-3 col-sm-6">
                <TeamCardStyleOne datas={datas} />
              </div>
            )}
          </DataIteration>
        </div> */}
        <div class="embed-responsive embed-responsive-16by9">
          <iframe
            width="560"
            height="315"
            class="embed-responsive-item"
            src={video}
            title="Next Cosmos Technology Digital Future Transformation"
            frameborder="0"
            sandbox=""
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen
          ></iframe>
        </div>
      </div>
    </section>
  );
}

export default TeamSection;
