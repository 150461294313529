import React from "react";
import FooterHomeTwo from "../../partials/Footers/index";
import HeaderHomeOne from "../../partials/Headers/Header";
import Enquire from "./Enquire";

export default function Layouts({ children, pageTitle, breadcrumbs = [] }) {
  return (
    <>
      <HeaderHomeOne />
      <Enquire pageTitle={pageTitle} breadcrumbs={breadcrumbs} />
      {children && children}
      <FooterHomeTwo />
    </>
  );
}
