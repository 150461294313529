import React from "react";
import Background from "../../../assets/images/footer/footer-bg-map.png";

export default function FooterHomeTwo({ className }) {
  return (
    <footer
      className={`main-footer footer-two bgs-cover text-white ${
        className || ""
      }`}
      style={{ backgroundImage: `url(${Background})` }}
    >
      <div className="container">
        <div className="footer-top pt-75">
          <ul className="contact-info">
            <li>
              <i className="fas fa-phone-alt"></i>
              <div className="content">
                <span>Call Us</span>
                <h5>
                  <a href="callto:+91 8851373820">+91 8851 373 820</a>
                </h5>
              </div>
            </li>
            <li>
              <i className="fas fa-envelope"></i>
              <div className="content">
                <span>Write to Us</span>
                <h5>
                  <a href="mailto:info@nextcosmostechnology.com">
                    info@nextcosmostechnology.com
                  </a>
                </h5>
              </div>
            </li>
            <li>
              <i className="fas fa-globe"></i>
              <div className="content">
                <span>Website</span>
                <h5>
                  <a href="https://nextcosmostechnology.com/">
                    www.nextcosmostechnology.com
                  </a>
                </h5>
              </div>
            </li>
          </ul>
        </div>
        <div className="footer-widget-area pt-85 pb-20">
          <div className="row">
            <div className="col-lg-3 col-sm-6">
              <div className="footer-widget about-widget">
                <h4 className="footer-title">
                  Next Cosmos Technology Pvt. Ltd.
                </h4>
                <div className="text">
                  Wide range of programming languages, frameworks, and
                  technologies to build efficient and scalable software systems.
                </div>
                <ul className="contact-info mt-20">
                  <li>
                    <i className="fas fa-map-marker-alt"></i>
                    <span>
                      B-7, 1st Floor Sector-2
                      <br />
                      Noida, Uttar Pradesh 201301
                    </span>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-2 col-6">
              <div className="footer-widget link-widget ml-20 rml-0">
                <h4 className="footer-title">Services</h4>
                <ul className="list-style-two">
                  <li>
                    <a href="#">Our Services</a>
                  </li>
                  <li>
                    <a href="#">Training Program</a>
                  </li>
                  <li>
                    <a href="#">Online Courses</a>
                  </li>
                  <li>
                    <a href="#">Digital Marketing</a>
                  </li>
                  <li>
                    <a href="#">Graphic Design</a>
                  </li>
                  <li>
                    <a href="#">Web Development</a>
                  </li>
                  <li>
                    <a href="#">SEO/SMO/PPC</a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-2 col-6">
              <div className="footer-widget link-widget ml-20 rml-0">
                <h4 className="footer-title">Explore</h4>
                <ul className="list-style-two">
                  <li>
                    <a href="#">Technologies</a>
                  </li>
                  <li>
                    <a href="#">Our Services</a>
                  </li>
                  <li>
                    <a href="#">Testimonials</a>
                  </li>
                  <li>
                    <a href="#">SaaS Solutions</a>
                  </li>
                  <li>
                    <a href="#">Case Studies</a>
                  </li>
                  <li>
                    <a href="#">Latest News</a>
                  </li>
                  <li>
                    <a href="#">Get in Touch</a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-3 col-6">
              <div className="footer-widget link-widget ml-20 rml-0">
                <h4 className="footer-title">Online Courses</h4>
                <ul className="list-style-two">
                  <li>
                    <a href="#">React/Node JS</a>
                  </li>
                  <li>
                    <a href="#">MERN Stack</a>
                  </li>
                  <li>
                    <a href="#">Full-Stack Development</a>
                  </li>
                  <li>
                    <a href="#">Type Script</a>
                  </li>
                  <li>
                    <a href="#">HTML5/CSS3</a>
                  </li>
                  <li>
                    <a href="#">ES6/Javascript</a>
                  </li>
                  <li>
                    <a href="#">Mangoes & Sequelize</a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-2 col-6">
              <div className="footer-widget link-widget ml-20 rml-0">
                <h4 className="footer-title">Technologies</h4>
                <ul className="list-style-two">
                  <li>
                    <a href="#">DevOps</a>
                  </li>
                  <li>
                    <a href="#">Agile methodology</a>
                  </li>
                  <li>
                    <a href="#">Postman</a>
                  </li>
                  <li>
                    <a href="#">Swagger</a>
                  </li>
                  <li>
                    <a href="#">Version Control Tool</a>
                  </li>
                  <li>
                    <a href="#">SSL/TLS</a>
                  </li>
                  <li>
                    <a href="#">Cloud Computing</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="copyright-area">
        <div className="container">
          <div className="copyright-inner pt-15">
            <div className="social-style-one mb-10">
              <a
                href="https://www.facebook.com/profile.php?id=100095164452579"
                target="blank"
              >
                <i className="fab fa-facebook-f"></i>
              </a>
              <a href="https://twitter.com/NextCosmosTech" target="blank">
                <i className="fab fa-twitter"></i>
              </a>
              <a
                href="https://www.linkedin.com/company/103130768/admin/feed/posts/"
                target="blank"
              >
                <i className="fab fa-linkedin"></i>
              </a>
              <a
                href="https://www.instagram.com/next_cosmos_technology"
                target="blank"
              >
                <i className="fab fa-instagram"></i>
              </a>
              <a
                href="https://in.pinterest.com/NextCosmosTechnology"
                target="blank"
              >
                <i className="fab fa-pinterest-p"></i>
              </a>
              <a
                href="https://www.youtube.com/@nextcosmostechnologypvt.ltd."
                target="blank"
              >
                <i className="fab fa-youtube"></i>
              </a>
            </div>
            <p>
              © 2022 - 2024 Copyright Next Cosmos Technology Pvt. Ltd.{" "}
              <a href="terms-conditions">
                <ins>Terms and Conditions</ins>
              </a>{" "}
              &{" "}
              <a href="privacy-policy">
                <ins>Privacy Policy</ins>
              </a>
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
}
