import React from "react";

const data = [
  {
    title: "Web Application Development",
  },
  {
    title: "Enterprise Software Solutions",
  },
  {
    title: "Cloud-based Applications",
  },
  {
    title: "Website Design and Development",
  },
  {
    title: "E-commerce Solutions",
  },
  {
    title: "Content Management Systems (CMS)",
  },
  {
    title: "Responsive Web Design",
  },
  {
    title: "Technology Strategy and Planning",
  },
  {
    title: "IT Infrastructure Assessment",
  },
  {
    title: "Security and Compliance Consulting",
  },
  {
    title: "Digital Transformation Advisory",
  },
  {
    title: "Technology Workshops",
  },
  {
    title: "Customized Training Sessions",
  },
  {
    title: "Skill Development Programs",
  },
];

export default function ServiceCard() {
  return (
    <div className="row justify-content-center">
      {data.map((ele) => {
        return (
          <div className="col-lg-4 col-sm-6">
            <div className="service-item-seven">
              <h3>{ele.title}</h3>
            </div>
          </div>
        );
      })}
    </div>
  );
}
